<template>
<div class="page-wrapper">
  <div class="upload-guid-wrapper">
    <img class="image" src="./assets/upload-img.jpg" alt="">
    <div class="text">点击上传获取手机文件</div>
  </div>
  <div class="upload-file-button-wrapper">
    点击上传
    <input class="upload-file-button" type="file" @change="handleFilesChange"/>
  </div>

  <!-- <div class="menu-list">
    <div class="card-style menu-item" v-for="(item, index) in menuList" :key="index" @click="chooseTheType(index)">
      <img class="icon" :src="item.icon"/>
      <div class="content">
        <div class="title">{{item.title}}</div>
        <div class="desc">{{item.desc}}</div>
      </div>
      <div class="arrow-right"></div>
      <input class="upload-file" v-if="index == 2" type="file" multiple @change="handleFilesChange"/>
    </div>
  </div> -->
  
  <!-- 进度提示 -->
  <div class="mask progress-wrapper" v-if="uploadingVisible">
    <div class="container card-style progress-container">
      <img class="icon" src="./assets/dealing.png"/>
      <div class="desc">加密处理、印后即删、保护隐私</div>
      <div class="progress">
        <div class="actived" :style="`width: ${!currTotalFileNum ? 0 : currUploadedNum / currTotalFileNum * currProgress * 100}%`"></div>
      </div>
      <div class="progress-desc">正在上传第{{currFileIndex}}个文件，总共{{currTotalFileNum}}个文件</div>
    </div>
  </div>
  <!-- 错误提示 -->
  <div class="mask" v-if="errorModalVisible">
    <div class="card-style container">
      <div class="title">提示</div>
      <div class="content">{{errorMessage}}</div>
      <div class="footer-buttons-wrapper">
        <div class="button" @click="errorModalVisible = false">取消</div>
        <div class="button active-button" @click="errorModalVisible = false">确定</div>
      </div>
    </div>
  </div>
  <!-- loading -->
  <div class="mask transparent" v-if="isLoading">
    <div class="loading-wrapper">
      <div class="loading-icon"></div>
      <div class="loading-text">{{loadingText}}</div>
    </div>
  </div>

</div>
</template>

<script>
import { uploadDocFile } from '@/api'
import { compressImage } from '@/utils'
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  data(){
    return {
      isLoading: false,
      loadingText: '加载中',
      loadingIcon: require('@/assets/loading.png'),
      menuList: [
        {
          title: '微信聊天文件',
          desc: '从微信聊天记录中选择文档打印，支持的文件格式：word、ppt、pdf等',
          icon: require('@/assets/images/wechat.png')
        },
        {
          title: '手机相册照片',
          desc: '手机照片打印在A4纸上，支持常用的照片格式：jpg、png',
          icon: require('@/assets/images/phone-album.png')
        },
        {
          title: '手机其他文件',
          desc: '手机本地文档一键打印，支持的文件格式：word、excel、ppt等',
          icon: require('@/assets/images/document-open.png')
        },
        {
          title: '复印',
          desc: 'A4文档、身份证/居住证、户口本、营业执照、驾驶证/行驶证、银行卡/社保',
          icon: require('@/assets/images/print.png')
        }
      ],
      uploadingVisible: false,
      currFileIndex: 1,
      currUploadedNum: 0,
      currTotalFileNum: 0,
      currProgress: 1,
      errorModalVisible: false,
      errorMessage: ''
    }
  },
  methods: {
    async handleFilesChange(e){
      // console.log('handleFilesChange', e)
      let files = e.target.files
      let tempFiles = [], hasShowedMsg = false
      for(let i = 0;i < files.length;i++){
        // if(/image/.test(files[i].type) && files[i].size >= 5 * 1024 * 1024){
        if(/image/.test(files[i].type)){
          if(!hasShowedMsg) {
            this.isLoading = true
            this.loadingText = '图片处理中'
            hasShowedMsg = true
          }
          tempFiles.push(await compressImage(files[i]))
        }else tempFiles.push(files[i])
      }
      console.log('compress', tempFiles)
      if(hasShowedMsg) this.isLoading = false
      this.uploadingVisible = true
      this.currTotalFileNum = files.length
      if(await this.uploadFilesInLoop(tempFiles)){
        wx.miniProgram.postMessage({data: {type: 2}})
        // wx.miniProgram.redirectTo({url: '/pages/document/fileList/fileList'})
        wx.miniProgram.navigateBack({
          delta: 1,
          fail: err =>{
            console.log(err)
            wx.miniProgram.switchTab({url: '/pages/index/index'})
          }
        })
      }
      this.uploadingVisible = false
    },
    async uploadFilesInLoop(files, index = 0){
      // if(!files || files.length < index + 1 || index < 0) return true
      let params = new FormData()
      // for(let i = 0;i < files.length;i++) params.append('file', files[i])
      params.append('file', files[index])
      params.append('type', 1)
      let res
      try {
        res = await uploadDocFile(params, {
          onUploadProgress: e => {
            console.log('onUploadProgress', e)
            if (e.lengthComputable) {
              let rate = e.loaded / e.total;  //已上传的比例
              if (rate < 1) {
                //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                //等响应回来时，再将进度设为100%
                this.currProgress = rate
              }
           }
          }
        })
      } catch (error) {
        console.log('error', error)
        this.errorMessage = error.message || '文件上传失败'
        this.errorModalVisible = true
        res = {data: {}}
        return false
      }
      let { code, msg, data } = res.data
      if(code == 1){
        console.log('success : ', data)
        this.currFileIndex += 1
        this.currUploadedNum += 1
        this.currProgress = 1
        if(index + 1 < files.length) return await this.uploadFilesInLoop(files, index + 1)
        else return true
        // return true
      }else{
        console.log('error : ', msg)
        this.errorMessage = msg || '上传失败'
        this.errorModalVisible = true
        return false
      }
    },
    chooseTheType(val){
      if(val == 2) return
      if(val == 3) return wx.miniProgram.navigateTo({url: '/pages/print/index/index'})
      wx.miniProgram.postMessage({data: {type: val}})
      wx.miniProgram.navigateBack({
        delta: 1,
        fail: err =>{
          console.log(err)
          wx.miniProgram.switchTab({url: '/pages/index/index'})
        }
      })
      // wx.miniProgram.redirectTo({url: '/pages/document/fileList/fileList'})
      // wx.miniProgram.redirectTo({url: '/pages/document/chooseImages/chooseImages'})
    }
  }
}
</script>

<style>
/* 100px = 1rem */
@media screen and (min-width: 300px) {html{font-size: 40px;}}
@media screen and (min-width: 375px) {html{font-size: 50px;}}
@media screen and (min-width: 414px) {html{font-size: 55.2px;}}
@media screen and (min-width: 540px) {html{font-size: 72px;}}
@media screen and (min-width: 750px) {html{font-size: 100px;}}
@media screen and (min-width: 960px) {html{font-size: 128px;}}
body, div, p, img{
  margin: 0;
  padding: 0;
  border: none;
}
.page-wrapper{
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}
.upload-guid-wrapper{
  padding: 1.5rem 0 0;
  text-align: center;
}
.upload-guid-wrapper .image{
  max-width: 4rem;
  max-height: 3.2rem;
}
.upload-guid-wrapper .text{
  color: #9EA2B2;
  font-size: .3rem;
}
.upload-file-button-wrapper{
  height: .9rem;
  color: #FFFFFF;
  overflow: hidden;
  font-size: .3rem;
  line-height: .9rem;
  border-radius: .9rem;
  background-color: #47CEC6;
  text-align: center;
  position: fixed;
  bottom: .3rem;
  right: .6rem;
  left: .6rem;
}
.upload-file-button-wrapper .upload-file-button{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.menu-list{
  padding-top: .3rem;
  width: 100%;
}
.menu-list .menu-item{
  margin: 0 .3rem .3rem;
  padding: .3rem;
  width: 6.9rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.menu-list .menu-item .upload-file{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.menu-list .menu-item .icon{
  flex-shrink: 0;
  max-width: 1.12rem;
  max-height: 1.12rem;
}
.menu-list .menu-item .content{
  flex: 1;
  margin: 0 .3rem;
}
.menu-list .menu-item .content .title{
  line-height: .45rem;
  font-size: .32rem;
  color: #0F1741;
}
.menu-list .menu-item .content .desc{
  margin-top: .1rem;
  line-height: .4rem;
  font-size: .28rem;
  color: #9EA2B2;
}
.menu-list .menu-item .arrow-right{
  flex-shrink: 0;
  width: .16rem;
  height: .16rem;
  border-style: solid;
  border-width: .02rem;
  border-color: transparent #999999 #999999 transparent;
  transform: rotate(-45deg);
}
/* 蒙层 */
.mask{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.mask .container{
  max-width: 80%;
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #ffffff;
}
/* 块状样式 */
.card-style{
  background-color: #ffffff;
  border-radius: .2rem;
}
/* 文件上传进度 */
.progress-wrapper .progress-container{
  padding: .3rem;
  width: 4.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.progress-wrapper .progress-container .icon{
  margin-top: -.3rem;
  height: 2.6rem;
  width: auto;
}
.progress-wrapper .progress-container .desc{
  margin-bottom: .3rem;
  line-height: .42rem;
  text-align: center;
  font-size: .3rem;
  color: #9EA2B2;
}
.progress-wrapper .progress-container .progress{
  width: 100%;
  height: .06rem;
  border-radius: .06rem;
  overflow: hidden;
  background-color: #E4E4E8;
}
.progress-wrapper .progress-container .progress .actived{
  width: 0;
  height: 100%;
  background-color: #47CEC6;
}
.progress-wrapper .progress-container .progress-desc{
  margin-top: .2rem;
  line-height: .42rem;
  text-align: center;
  font-size: .3rem;
  color: #333333;
}
/* 弹框样式 */
.mask .container{
  width: 5.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mask .container .title{
  padding: 0 .3rem;
  text-align: center;
  line-height: .48rem;
  font-size: .36rem;
  color: #333333;
}
.mask .container .error-icon:first-child,
.mask .container .title:first-child{
  margin-top: .3rem;
}
.mask .container .title.only-title{
  margin: .45rem 0 .15rem;
}
.mask .container .content{
  margin-top: .2rem;
  padding: 0 .3rem;
  line-height: .48rem;
  color: #999999;
  font-size: .32rem;
  text-align: center;
}
.mask .container .content:last-child{
  margin-bottom: .3rem;
}
.mask .container .error-icon{
  margin-bottom: .1rem;
  width: 1.16rem;
  height: 1.14rem;
}
.mask .container .footer-buttons-wrapper{
  margin-top: .3rem;
  width: 100%;
  height: .94rem;
  line-height: .94rem;
  border-top: .01rem solid #e5e5e5;
  display: flex;
}
.mask .container .footer-buttons-wrapper .button{
  flex: 1;
  margin: 0;
  padding: 0;
  height: .94rem;
  line-height: .94rem;
  text-align: center;
  font-weight: normal;
  background-color: transparent;
  font-size: .36rem;
  color: #333333;
  border-radius: 0;
}
.mask .container .footer-buttons-wrapper .button:not(:last-child){
  border-right: .01rem solid #e5e5e5;
}
.mask .container .footer-buttons-wrapper .button.active-button{
  color: #47cec6;
}
/* loading */
.mask.transparent{
  background-color: transparent;
}
.mask.transparent .loading-wrapper{
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, .8);
  border-radius: .2rem;
  color: #fefefe;
}
.mask.transparent .loading-wrapper .loading-icon{
  width: .72rem;
  height: .72rem;
  border-radius: 50%;
  background-size: cover;
  background-color: #ffffff;
  background-image: conic-gradient(transparent, rgba(0, 0, 0, .8));
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask: url(./assets/loading.png) no-repeat;
  mask: url(./assets/loading.png) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  animation: rotation .8s ease-in-out infinite;
  -o-animation: rotation .8s ease-in-out infinite;
  -moz-animation: rotation .8s ease-in-out infinite;
  -webkit-animation: rotation .8s ease-in-out infinite;
}
@keyframes rotation {
  0% { background-image: conic-gradient(from 0deg, transparent, rgba(0, 0, 0, .8)); }
  10% { background-image: conic-gradient(from 36deg, transparent, rgba(0, 0, 0, .8)); }
  20% { background-image: conic-gradient(from 72deg, transparent, rgba(0, 0, 0, .8)); }
  30% { background-image: conic-gradient(from 108deg, transparent, rgba(0, 0, 0, .8)); }
  40% { background-image: conic-gradient(from 144deg, transparent, rgba(0, 0, 0, .8)); }
  50% { background-image: conic-gradient(from 180deg, transparent, rgba(0, 0, 0, .8)); }
  60% { background-image: conic-gradient(from 216deg, transparent, rgba(0, 0, 0, .8)); }
  70% { background-image: conic-gradient(from 252deg, transparent, rgba(0, 0, 0, .8)); }
  80% { background-image: conic-gradient(from 288deg, transparent, rgba(0, 0, 0, .8)); }
  90% { background-image: conic-gradient(from 324deg, transparent, rgba(0, 0, 0, .8)); }
  100% { background-image: conic-gradient(from 360deg, transparent, rgba(0, 0, 0, .8)); }
}
.mask.transparent .loading-wrapper .loading-text{
  margin-top: .36rem;
  font-size: .32rem;
}
</style>
