// 解析url上的参数
export function getQueryVariable(variable){
  let query = window.location.search.substring(1)
  let vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    if(pair[0] == variable) return pair[1] || true
  }
  return false
}

// 压缩图片
export function compressImage(file){
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => { 
      const img = new Image()
      img.onload = () => {
        const {width, height} = img;
        const r = width / height, maxLen = 1920  // Math.min(width, height, 1920);
        let w = width, h = height;
        if(r > 1){
          w = maxLen
          h = w / r
        } else {
          h = maxLen
          w = h * r
        }
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = w
        canvas.height = h
        ctx.drawImage(img, 0, 0, width, height, 0, 0, w, h)
        resolve( converBase64UrlToBlob(canvas.toDataURL(file.type), file.type, file.name) )
      }
      img.onerror = (err) => { console.log('image load error', err); resolve(file) }
      img.src = reader.result
    }
    reader.onerror = (err) => { console.log('reader load error', err); resolve(file) }
  })
}

// 将以base64的图片url数据转换为Blob
function converBase64UrlToBlob(urlData, type, name){
  let bytes = window.atob(urlData.split(',')[1])
  let n = bytes.length
  let u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bytes.charCodeAt(n)
  }
  return new File([u8arr], name, {type})
}