import request from '@/utils/request'
import { getQueryVariable } from '@/utils'

export function uploadDocFile(data, otherArgs){
  return request(Object.assign({
    url: '/applet/userPrint/uploadFile',
    headers: { Authorization: getQueryVariable('token') },
    method: 'post',
    data
  }, {...otherArgs}))
}